<template>
  <div>
    <el-upload
      v-if="!fileUrl"
      class="json-uploader"
      :headers="headers"
      :action="getUploadUrl"
      :data="formData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-progress="handleUploadProgress"
      :before-upload="beforeFileUpload"
      :limit="1"
      :multiple="false"
      drag
    >
      <UploaderInfoIcon />
      <div class="el-upload__tip" slot="tip">
        {{ __("json file with a size less than 512kb") }}
      </div>
    </el-upload>
    <div
      v-if="fileUrl"
      style="display: flex;align-items: center;justify-content: flex-start"
    >
      <div
        style="height: 120px; width: 140px; display: flex;align-items: center;justify-content: center;border-radius: 6px; border: var(--theme-color) 1px dashed"
        v-if="fileUrl"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center; justify-content: center"
        >
          <div
            style="max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;
  overflow: hidden;font-size: 0.8em; margin-top: 5px"
          >
            {{ fileName }}
          </div>
          <div style="padding-top: 5px;">
            <el-button
              @click="handleDelete"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUploader from "@/components/uploaders/BaseUploader";
import UploaderInfoIcon from "../UploaderInfoIcon.vue";

export default {
  mixins: [BaseUploader],
  components: {
    UploaderInfoIcon
  },
  props: {
    fileName: {
      required: false,
      type: String
    }
  },
  methods: {
    beforeFileUpload(file) {
      this.isLoading = true;
      this.$emit("on-progress");

      const isJSON = file.type === "application/json";

      const isLt512kb = file.size / 1024 < 512;

      if (!isJSON) {
        this.$message({
          message: __("Only JSON files are allowed"),
          type: "error"
        });
      }

      if (isJSON && !isLt512kb) {
        this.$message({
          message: __("file size exceeded :max :unit", {
            max: 512,
            unit: "kB"
          }),
          type: "error"
        });
      }

      if (!(isJSON && isLt512kb)) {
        this.isLoading = false;
        this.$emit("on-error");
      }

      return isJSON && isLt512kb;
    },
    handleDelete() {
      this.$emit("on-delete");
    }
  }
};
</script>

<style scoped lang="scss">
.json-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ::v-deep .el-upload-dragger {
    width: 140px;
    height: 140px;
    padding: 5px;
  }
}
</style>
