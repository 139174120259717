<template
  ><div style="width: 100%;" @click="handleClear">
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!canWrite()"
      >{{ __("Create Firebase Profile") }}</el-button
    >
    <div style="padding: 0 36px;margin-top: 165px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
          :auto-refresh-content="!modalCoverContent"
          :refresh-interval="600000"
        >
        </PaginationToolbar>
      </el-form>

      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="firebaseProfilesTable"
            v-loading="loading"
            :data="profiles"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @current-change="handleCurrentChange"
            highlight-current-row
            class="list-table"
            v-show="canRead()"
          >
            <el-table-column :label="__('Profile Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.profile_name }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Firebase Database URL')">
              <template slot-scope="scope">
                <span>{{ scope.row.db_url }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Status')">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div :style="getStatusStyles(scope.row.is_alive)"></div>
                  <span>{{ statusOkOrError(scope.row.is_alive) }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "FirebaseProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  data() {
    return {
      search: "",
      sortByColumns: ["profile_name", "profile_id"],
      currentRow: ""
    };
  },
  computed: {
    ...mapState("nodeLoggingProfiles", {
      profiles: state => state.firebaseProfiles,
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions("nodeLoggingProfiles", {
      contentAPI: "getNodeLoggingProfiles",
      deleteContentMethod: "deleteNodeLoggingProfile",
      undoDeleteContent: "undoDeleteProfile"
    }),
    handleClear() {
      this.$refs["firebaseProfilesTable"].setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },
    getStatusStyles(status) {
      let state = status ? "success" : "error";
      let statusStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px"
      };

      const statusColorMap = {
        success: "#1CD1A1",
        error: "#F84856"
      };

      statusStyles["background-color"] = statusColorMap[state] || "#1CD1A1";
      return statusStyles;
    },

    /**
     * Display OK or Error depending on the isAlive status
     */
    statusOkOrError(isAlive) {
      return isAlive ? __("OK") : __("Error");
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== undefined && val !== null && val !== -1) {
          this.$refs["firebaseProfilesTable"] &&
            this.$refs["firebaseProfilesTable"].setCurrentRow(
              this.profiles[val]
            );
        } else if (val === -1) {
          this.$refs["firebaseProfilesTable"] &&
            this.$refs["firebaseProfilesTable"].setCurrentRow();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
