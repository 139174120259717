<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Profile Name") }}</div>
    <div class="details">{{ contentFormInfo.profile_name }}</div>

    <div class="titles">{{ __("Firebase Database URL") }}</div>
    <div class="details">{{ contentFormInfo.db_url }}</div>

    <div class="titles profile-tasks">
      <div class="click-to-open">
        <span class="trigger" @click="getTaskListForProfile(contentFormInfo)">{{
          __("Show tasks using this profile")
        }}</span>
      </div>
    </div>

    <el-button
      @click="handleEditBtnClick"
      class="editBtn"
      :disabled="!canWrite()"
      >{{ __("Edit Profile") }}
    </el-button>
    <el-button
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      :disabled="!canWrite()"
      >{{ __("Delete") }}
    </el-button>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false">
        <div style="max-height: 100vh;">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <page-header :title="__('profile')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="profile_name" :label="__('Profile Name')">
                  <el-input
                    :placeholder="__('Enter Profile Name')"
                    v-model="contentForm.profile_name"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="db_url"
                  :label="__('Firebase Database URL')"
                >
                  <div class="form-item-url">
                    <el-input
                      :placeholder="__('Enter Firebase Database URL')"
                      v-model="contentForm.db_url"
                    />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <div class="service-provider-json">
                  <div>
                    <el-form-item
                      prop="credentials_file_path"
                      :label="__('Upload Service Account JSON')"
                    ></el-form-item>
                    <json-uploader
                      :file-name="file_name"
                      :file-url="file_url"
                      folder="uploads/node-logging-profiles/firebase"
                      encrypt-contents
                      @on-success="uploadFileHandleSuccess"
                      @on-delete="uploadedFileHandleDelete"
                      @on-progress="isUploading = true"
                      @on-error="uploadFileHandleError"
                    />
                  </div>
                  <div>
                    <el-button
                      @click="testConnection"
                      :disabled="testing || isUploading"
                      :loading="testing"
                      class="test-connection-btn"
                      size="mini"
                      :icon="getIcon"
                      :type="testResult"
                      >{{ __("Test Connection") }}
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex; margin-top: 35px; margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              :disabled="isUploading"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
    <tasks-for-profile-dialog
      v-if="showProfileUsedInTasksModal"
      :profile="contentFormInfo"
      :show-modal="showProfileUsedInTasksModal"
      :tasks="tasks"
      :loading="fetchingTasks"
      @close="showProfileUsedInTasksModal = false"
    />
  </div>
</template>

<script>
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import {
  checkProfileNameExists,
  testFirebaseConnection
} from "@/api/nodeLoggingProfiles";
import { urlFieldValidation } from "@/utils/formValidationRules";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import JsonUploader from "@/components/uploaders/types/JsonUploader";
import { removeFiles } from "@/api/services";
import _ from "lodash";
import path from "path";
import TasksForProfileDialog from "@/views/manage/node-logging-profiles/components/tasksForProfileDialog";
import GetTasksForProfiles from "@/views/manage/node-logging-profiles/mixins/GetTasksForProfiles";
import SaveButton from "@/components/SaveButton";

export default {
  components: { TasksForProfileDialog, JsonUploader, PageHeader, SaveButton },
  mixins: [GetTasksForProfiles, BaseContent, BaseContentInfoPanel],
  data() {
    const validateURL = (rule, value, callback) => {
      let isValid = urlFieldValidation(rule, value);
      isValid ? callback() : callback(__("enter valid URL"));
    };
    const validateProfileName = async (rule, value, callback) => {
      let hasError = false;
      await checkProfileNameExists(value, NODE_LOG_PROFILE.FIREBASE_PROFILE)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.profile_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Profile Name already taken"));
      } else {
        callback();
      }
    };
    return {
      isUploading: false,
      uploadedFilePaths: [],
      originalServiceAccountFile: "",
      file_url: "",
      file_name: "",
      rules: {
        profile_name: [
          {
            required: true,
            message: __("Profile Name cannot be empty"),
            trigger: "blur"
          },
          { validator: validateProfileName, trigger: "blur" }
        ],
        db_url: [
          {
            required: true,
            message: __("Firebase Database URL cannot be empty"),
            trigger: "blur"
          },
          { validator: validateURL, trigger: "blur" }
        ],
        credentials_file_path: [
          {
            required: true,
            message: __("service account JSON file is required")
          }
        ]
      },
      isSubmitting: false,
      testing: false,
      testResult: "default",
      notifications: []
    };
  },
  computed: {
    ...mapState("nodeLoggingProfiles", {
      isLoading: state => state.loading
    }),

    getIcon() {
      switch (this.testResult) {
        case "default":
          return "";
        case "success":
          return "el-icon-circle-check";
        case "danger":
          return "el-icon-circle-close";
        default:
          return "";
      }
    },

    generateName() {
      return this.contentForm.credentials_file_path
        ? _(_.split(path.basename(this.contentForm.credentials_file_path), "_"))
            .tail()
            .value()
            .join("_")
        : "service_account";
    }
  },
  methods: {
    ...mapActions("nodeLoggingProfiles", {
      deleteContentMethod: "deleteNodeLoggingProfile",
      undoDeleteContent: "undoDeleteProfile",
      createProfile: "createNodeLoggingProfile",
      updateProfile: "updateNodeLoggingProfile"
    }),

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    testConnection() {
      if (!this.contentForm.credentials_file_path || !this.contentForm.db_url) {
        this.$refs.contentForm.validateField("credentials_file_path");
        this.$refs.contentForm.validateField("db_url");
      } else {
        this.testing = true;
        testFirebaseConnection({
          credentials_file_path: this.contentForm.credentials_file_path,
          db_url: this.contentForm.db_url
        })
          .then(({ data }) => {
            this.testResult = data.test_status ? "success" : "danger";
            this.notifications.push(
              this.$message({
                type: data.test_status ? "success" : "error",
                message: data.test_status
                  ? __("connection established")
                  : __("failed to establish connection")
              })
            );
          })
          .catch(err => {
            this.notifications.push(
              this.$message({
                type: "error",
                message: err.message,
                duration: 7500
              })
            );
            this.testResult = "danger";
          })
          .finally(() => {
            this.testing = false;
          });
      }
    },

    uploadFileHandleSuccess(url) {
      this.isUploading = false;
      this.uploadedFilePaths.push(url.path);
      this.contentForm.credentials_file_path = url.path;
      this.file_url = url.url;
      this.file_name = this.generateName;
      this.$refs.contentForm.validateField("credentials_file_path");
    },

    uploadedFileHandleDelete() {
      // if someone press delete on the original json file during edit
      if (this.id !== -1 && !this.originalServiceAccountFile) {
        this.originalServiceAccountFile = this.contentForm.credentials_file_path;
      }
      this.file_url = "";
      this.file_name = "";
      this.contentForm.credentials_file_path = "";
    },

    uploadFileHandleError() {
      this.isUploading = false;
    },

    handleCancelBtn(removeUploadedFiles = true) {
      this.file_url = this.contentFormInfo.credentials_file_path;
      this.file_name = this.generateName;

      _.map(this.notifications, notification => notification.close());
      this.notifications = [];

      if (removeUploadedFiles) {
        !_.isEmpty(this.uploadedFilePaths) &&
          removeFiles(this.uploadedFilePaths);
      } else {
        const filesToRemove = _.reject(
          this.uploadedFilePaths,
          path => path === this.contentForm.credentials_file_path
        );
        !_.isEmpty(filesToRemove) && removeFiles(filesToRemove);
      }

      if (!removeUploadedFiles && this.originalServiceAccountFile) {
        removeFiles([this.originalServiceAccountFile]);
      }

      this.uploadedFilePaths = [];
      this.originalServiceAccountFile = "";
      this.testing = false;
      this.testResult = "default";
      this.resetForm("contentForm");
      this.handleCancel();
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createProfile : this.updateProfile;
          this.isSubmitting = true;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("profile created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancelBtn(false);
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    }
  },
  watch: {
    "contentForm.credentials_file_path": {
      handler() {
        this.testResult = "default";
      }
    },
    contentFormInfo: {
      immediate: true,
      handler(profile) {
        if (_.get(profile, "profile_id", -1) !== -1) {
          this.file_url = this.contentForm.credentials_file_path;
          this.file_name = this.generateName;
        } else {
          this.file_url = "";
          this.file_name = "";
        }
        this.testResult = "default";
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.page-header {
  padding-left: 0;
}

.form-item-url {
  display: flex;

  .http_method_dropdown {
    width: 100px;
    color: black;

    ::v-deep .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input.is-focus .el-input__inner {
      border-color: #a0a8b5 !important;

      &:focus {
        border-color: #a0a8b5 !important;
      }
    }

    ::v-deep .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .url-input-field {
    flex: 1;

    ::v-deep .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.http_methods {
  border-radius: 4px 0 0 4px !important;
}

.service-provider-json {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .test-connection-btn {
    width: 135px;
  }
}

.profile-tasks {
  .click-to-open {
    display: flex;
    color: var(--theme-color);
    margin: 20px 0;
    font-size: 0.85rem;

    .trigger {
      cursor: pointer;
    }
  }
}
</style>
