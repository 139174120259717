<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "@/views/manage/node-logging-profiles/firebase-profiles/pages/list";
import CreateOrEdit from "@/views/manage/node-logging-profiles/firebase-profiles/pages/createOrEdit";
import { mapState } from "vuex";
import { NODE_LOG_PROFILE } from "@/constants/nodeLoggingProfiles";
import _ from "lodash";

export default {
  name: "FirebaseProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("nodeLoggingProfiles", {
      contents: state => state.firebaseProfiles
    })
  },

  methods: {
    initContent() {
      return {
        profile_type: NODE_LOG_PROFILE.FIREBASE_PROFILE,
        profile_name: "",
        credentials_file_path: "",
        db_url: "",
        is_enabled: 1
      };
    }
  },
  mounted() {
    this.contentType = _.startCase(NODE_LOG_PROFILE.FIREBASE_PROFILE);
    this.primaryKey = "profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
